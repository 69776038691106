
import { Options, Vue } from "vue-property-decorator";
import pdf from "@/components/pdf/index.vue";
import api from "@/api/disciplinesafe.ts";
import axios from "axios";
@Options({
  name: "Disciplinestudydetail",
  components: {
    pdf,
  },
})
export default class extends Vue {
  params: any = {};
  showData: any = {};
  mounted() {
    this.params = this.$route.params;
    this.browse();
    this.getUrl();
  }
  getUrl() {
    axios({
      url: this.params.fileUrl,
      method: "get",
      responseType: "blob",
    }).then((res: any) => {
      var blob = new Blob([res.data], { type: res.headers["content-type"] });
      var url = window.URL.createObjectURL(blob);
      if (!url) {
        url = window.webkitURL.createObjectURL(blob);
      }
      (this as any).$refs.pdf.fSetPdf(url);
    });
  }
  browse() {
    api.incrBrowse(this.params.id).then((res: any) => {
      if (res.code === 0) {
        this.getBrowseThumbs();
      }
    });
  }
  getBrowseThumbs() {
    api.getBrowseThumbs(this.params.id).then((res: any) => {
      if (res.code !== 0) {
        return this.$toast.fail(res.msg);
      }
      this.showData = res.data;
    });
  }
  fDz() {
    api.incrThumbs(this.params.id).then((res: any) => {
      if (res.code === 0) {
        this.getBrowseThumbs();
      }
    });
  }
  fBrowse() {
    this.$router.push({
      name: "Disciplinerecord",
      params: {
        id: this.params.id,
      },
    });
  }
}
