
import { Prop, Vue } from "vue-property-decorator";
import Pdfh5 from "pdfh5";
import "pdfh5/css/pdfh5.css";
export default class extends Vue {
  pdf = null;
  fSetPdf(url: string) {
    this.pdf = new Pdfh5("#pdf", {
      pdfurl: url,
    });
  }
}
